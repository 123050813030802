import {ApplicationStatusesEnum} from '../enums/application-statuses.enum';
import {ConstructorDocument} from '../applicationConstructor/constructor-document.model';
import {TabsSettings} from '../applicationConstructor/tabs-settings.model';
import {KendoProperties} from '../enums/kendo-properties.enum';
import {PropertyValue} from './application-property-value.model';
import {Dict} from "../dict.model";
import {ApplicationApprovalSettings} from './approvalList.model';

export class ApplicationMainForm {
  category: string = '';
  type: string = '';
  dictTypeReceiptId: string = '';
}

export interface GetApplication {
  externalId: string;
  applicationNumber: number;
  applicationConstructorId: string;
  dictReceiptType: Dict;
  statusEnum: ApplicationStatusesEnum;
  applicationStatusInfo: StatusInfo;
  notificationCount: number;
  registrationNumber?: RegistrationNumber;
  issueDate?: Date;
  applicationCategoryName: string;
  applicationTypeName: string;
  approvalSettings: ApplicationApprovalSettings[];
  documents: ConstructorDocument[];
  tabs: TabsSettings[];
}

export interface StatusInfo {
  statusUpdatedAt: Date;
  statusChangePersonId?: string;
  personFIO?: string;
  statusChangeStudentId?: string;
  studentFIO?: string;
}

export interface RegistrationNumber {
  registrationNumber?: number;
  registrationNumberLitera?: string;
}

export interface ApplicationForm {
  applicationConstructorId?: string;
  dictTypeReceiptId?: string;
  applicationPropertiesValueDto: ApplicationFormProperty[];
}

export interface ApplicationTabData {
  index: number;
  applicationPropertiesValueDto: ApplicationFormProperty[];
}

export interface ApplicationFormProperty {
  applicationConstructorPropertyId: string;
  propertyType: KendoProperties;
  value: PropertyValue|File[]|null;
}

export interface GroupPropertyForm {
  properties: PropertyForm[];
}

export interface PropertyForm {
  externalId: string;
  propertyTypeEnum: KendoProperties;
  propertyValue: PropertyValue|File[]|null;
}
