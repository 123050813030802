import {Injectable} from '@angular/core';
import {map, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {ApplicationCategory} from '../../../models/mfc/dicts/application-category.model';


@Injectable({
  providedIn: 'root'
})
export class ApplicationCategoryService {
  private baseUrl = `${environment.apiEndpoint}${environment.mfc.apiPaths.dict}/ApplicationCategory/`;

  constructor(private http: HttpClient) { }

  public getApplicationCategories(): Observable<ApplicationCategory[]> {
    return this.http.get<ApplicationCategory[]>(this.baseUrl)
      .pipe(map((data) => data.filter(a => !a.hidden) ?? []));
  }
}
