import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ApplicationStatusesEnum} from '../../../../models/mfc/enums/application-statuses.enum';
import {
  ApplicationConstructorSettings
} from '../../../../models/mfc/applicationConstructor/application-constructor-settings.model';
import {GetApplication} from '../../../../models/mfc/applicationForm/application-form.model';
import {TabsSettings} from '../../../../models/mfc/applicationConstructor/tabs-settings.model';
import {ApplicationTabsEnum} from 'src/app/models/mfc/enums/application-tabs.enum';
import {ApplicationDocumentList} from '../../../../models/mfc/applicationForm/document.model';

@Component({
  selector: 'mfc-application-form-tab',
  templateUrl: './application-form-tab.component.html',
  styleUrls: ['./application-form-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplicationFormTabComponent implements OnInit {

  @Input() tabId?: string = '';
  @Input() tabSelector = '';
  @Input() index = 0;
  @Input() status?: ApplicationStatusesEnum;
  @Input() editable = false;
  @Input() settings?: ApplicationConstructorSettings;
  @Input() application?: GetApplication;
  @Input() documents: ApplicationDocumentList = new ApplicationDocumentList();

  protected dynamicTab?: TabsSettings;

  protected readonly ApplicationTabsEnum = ApplicationTabsEnum;

  constructor() { }

  ngOnInit(): void {
    this.dynamicTab = (this.settings ?? this.application)?.tabs
      .find((item: TabsSettings) => item.externalId === this.tabId);
  }

}
