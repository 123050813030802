import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ApprovalStatus} from '../../../models/mfc/dicts/approval-status.model';

@Injectable({
  providedIn: 'root'
})
export class ApprovalStatusService {
  private baseUrl = `${environment.apiEndpoint}${environment.mfc.apiPaths.dict}/ApprovalStatus/`;

  constructor(private http: HttpClient) { }

  public getApprovalStatuses(): Observable<ApprovalStatus[]> {
    return this.http.get<ApprovalStatus[]>(this.baseUrl).pipe(map((data) => data ?? []));
  }

  public getApprovalStatus(id: string): Observable<ApprovalStatus> {
    return this.http.get<ApprovalStatus>(`${this.baseUrl}${id}`);
  }
}
