<div style="display: flex">
    <h2>Перечень компетенций для дисциплины</h2>
    <button kendoButton class="returnBtn" (click)="returnToEducation()">Назад</button>
</div>
<div *ngIf="loading" class="loading-container">
    <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
</div>
<kendo-grid
        [data]="competenceMatrixData"
        *ngIf="!loading"
        scrollable="none" >
    <kendo-grid-column headerClass="gridHeader" [width]="450" title="Дисциплина">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="gridCell">
                {{disciplineName}}
            </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="Code" headerClass="gridHeader" title="Код компетенции">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="gridCell" style="text-align: center">
                {{dataItem.Code}}
            </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="Description" headerClass="gridHeader" title="Описание компетенции">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="gridCell">
                {{dataItem.Description}}
            </span>
        </ng-template>
    </kendo-grid-column>
</kendo-grid>
