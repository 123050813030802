import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ApplicationStatus} from '../../../models/mfc/dicts/application-status.model';
import {map} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ApplicationStatusService {
  private readonly baseUrl = `${environment.apiEndpoint}${environment.mfc.apiPaths.dict}/ApplicationStatus/`;

  constructor(private http: HttpClient) { }

  public getAll(): Observable<ApplicationStatus[]> {
    return this.http.get<ApplicationStatus[]>(this.baseUrl).pipe(map((data) => data ?? []));
  }
}
