<div class="grid-layout-container">
  <kendo-gridlayout class="studentLayout"
    [align]="align"
    [gap]="{ rows: 10, cols: 20}">
    <kendo-gridlayout-item class="profilePictureColumn">
      <div class="profilePicture" [style.background-image]="photoUrl" >
      </div>
      <h3 class="profilePictureColumn__name">
        {{studentModel.studPerson.lastName + " " + studentModel.studPerson.firstName + " " + studentModel.studPerson.middleName}}
      </h3>
      <div class="contacts">
        <h2 class="contacts__title">Контакты</h2>
        <div class="email">
          <table class="table email">
            <tr class="spaceUnder">
              <th>E-mail</th>
            </tr>
          </table>
          <table class="table value">
            <tr class="spaceUnder">
              <td>{{emailValue}}</td>
            </tr>
          </table>
        </div>
        <div class="phone">
          <table class="table phone">
            <tr class="spaceUnder">
              <th>Телефон</th>
            </tr>
          </table>
          <table class="table value">
            <tr class="spaceUnder">
              <td>{{phoneFormat(phoneValue)}}</td>
            </tr>
          </table>
        </div>
        <div class="support">
          <h2 class="supportTitle">Техническая поддержка ЛК</h2>
          <table class="table" style="margin-top: 3px;">
            <tr class="spaceUnder">
              <td>E-mail  <a class="mailLink" href="mailto:{{supportEmail}}">{{supportEmail}}</a></td>
            </tr>
          </table>
        </div>
      </div>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item [col]="2" [colSpan]="4" [row]="1">
      <h1 style="margin-top: 0">Основная информация</h1>
      <!-- <kendo-loader style="position: absolute; left: 50%" *ngIf="!hasLoaded" type="converging-spinner" size="medium"></kendo-loader> -->
    </kendo-gridlayout-item>
    <kendo-gridlayout-item class="rightRow">
      <h2 class="hiddenTitle">Заголовок</h2>
      <table class="studentInfo">
        <tr>
          <th>Кафедра</th>
          <td>{{departmentValue}}</td>
        </tr>
        <tr>
          <th>Академический статус</th>
          <td>
            {{academicStatusValue}}
          </td>
        </tr>
        <tr *ngIf="!hideOrganizationValue">
          <th>Заказчик целевого обучения</th>
          <td>
            {{organizationValue}}
          </td>
        </tr>
        <tr *ngIf="!shortInformation">
          <th>Изучаемые языки</th>
          <td>{{languagesValue}}</td>
        </tr>
        <tr *ngIf="!shortInformation">
          <th>Средний балл</th>
          <td>-</td>
        </tr>
        <tr *ngIf="!shortInformation">
          <th>Дата начала обучения</th>
          <td>
            <div>{{dateStartValue}}</div>
          </td>
        </tr>
        <tr *ngIf="!shortInformation">
          <th>Год выпуска</th>
          <td>
            <div>{{dateEndValue}}</div>
          </td>
        </tr>
      </table>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item [col]="2" [colSpan]="2" [row]="2">
      <div class="educationTitle">
        <h2>Информация об обучении</h2>
        <button *ngIf="shortInformation" class="k-display-inline-block arrowButton arrow" (click)="showStudentInformation()" [ngClass]="shortInformation ? 'noRotate':'rotate'"></button>
      </div>
      <div *ngIf="shortInformation">
        <table class="studentInfo">
            <tr>
                <th>Номер зачетной книжки</th>
                <td>{{studentNumberValue}}</td>
            </tr>
            <tr>
                <th>Источник финансирования</th>
                <td *ngIf="hasLoaded">
                  {{budgetStateValue}}
                </td>
            </tr>
            <tr>
                <th>Форма обучения</th>
                <td>{{studyFormValue}}</td>
            </tr>
        </table>
      </div>
      <div *ngIf="!shortInformation">
        <table class="studentInfo">
          <tr>
            <th>Номер зачетной книжки</th>
            <td>{{studentNumberValue}}</td>
          </tr>
          <tr>
            <th>Источник финансирования</th>
            <td>{{budgetStateValue}}</td>
          </tr>
          <tr>
            <th>Форма обучения</th>
            <td>{{studyFormValue}}</td>
          </tr>
          <tr>
            <th>Институт (факультет)</th>
            <td>{{facultyValue}}</td>
          </tr>
          <tr>
            <th>Курс</th>
            <td>{{courseValue}}</td>
          </tr>
          <tr>
            <th>Группа</th>
            <td>{{groupNameValue}}</td>
          </tr>
          <tr>
            <th>Направление обучения</th>
            <td>{{studyDirection}}</td>
          </tr>
          <tr *ngIf="hasProfile()">
            <th>Профиль (программа)</th>
            <td>{{profile}}</td>
          </tr>
        </table>
      </div>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item class="infoBlock" [row]="3" [col]="2" [colSpan]="5" style="margin-top: 13px">
      <h3 class="infoMsg" [innerHTML]="infoMessage">
      </h3>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item *ngIf="!hidden" [row]="3" [col]="2" [colSpan]="5" style="margin-top: 13px">
      <h2 style="margin-bottom: 20px;" [ngClass]="shortInformation ? 'marginForShort':'marginForLong'">
        Расписание занятий на сегодня - {{currentDate | date:'dd.MM.YYYY'}} {{"(" + dayName + ")"}}
      </h2>
      <div class="dataBlock">
        <div class="discipline onGoing">
          <div class="timeAndWeek">
            <span class="k-icon k-i-clock"></span>
            <h2 class="disciplineTime">10:00 - 11:00</h2>
            <h2 class="weekType">чётная неделя</h2>
          </div>
          <h2 class="disciplineTitle">Психологическое сопровождение пациентов в общей медицинской практике</h2>
          <div class="text">
            <h2 class="itemText">Преподаватель: Иванов Иван Иванович</h2>
            <h2 class="itemText">Расположение: 403 (III)</h2>
            <h2 class="itemText">Тип занятия: лекция</h2>
          </div>
        </div>
        <div class="discipline">
          <div class="timeAndWeek">
            <span class="k-icon k-i-clock"></span>
            <h2 class="disciplineTime">10:00 - 11:00</h2>
            <h2 class="weekType">чётная неделя</h2>
          </div>
          <h2 class="disciplineTitle">Психологическое сопровождение пациентов в общей медицинской практике</h2>
          <div class="text">
            <h2 class="itemText">Преподаватель: Иванов Иван Иванович</h2>
            <h2 class="itemText">Расположение: 403 (III)</h2>
            <h2 class="itemText">Тип занятия: лекция</h2>
          </div>
        </div>
        <div class="discipline">
          <div class="timeAndWeek">
            <span class="k-icon k-i-clock"></span>
            <h2 class="disciplineTime">10:00 - 11:00</h2>
            <h2 class="weekType">чётная неделя</h2>
          </div>
          <h2 class="disciplineTitle">Психологическое сопровождение пациентов в общей медицинской практике</h2>
          <div class="text">
            <h2 class="itemText">Преподаватель: Иванов Иван Иванович</h2>
            <h2 class="itemText">Расположение: 403 (III)</h2>
            <h2 class="itemText">Тип занятия: лекция</h2>
          </div>
        </div>
      </div>
      <h2 style="margin-top: 30px; margin-bottom: 20px;">
        Объявления
      </h2>
      <div class="dataBlock">
        <div class="eventItem">
          <h2 class="eventTitle">Перевод на дистанционное обучение</h2>
          <div class="text">
            <h2 class="itemText">Группа 615 переводится на дистанционное обучение с 26 октября и до окончания цикла 6
              ноября.
              так как 25 октября у студентки Залетовой был получен положительный анализ на COVID-19</h2>
            <h2 class="author">Курникова Анна Александровна, 20.03.2022</h2>
          </div>
        </div>
        <div class="eventItem hasViewed">
          <h2 class="eventTitle">Перевод на дистанционное обучение</h2>
          <div class="text">
            <h2 class="itemText">Группа 615 переводится на дистанционное обучение с 26 октября и до окончания цикла 6
              ноября.
              так как 25 октября у студентки Залетовой был получен положительный анализ на COVID-19</h2>
            <h2 class="author">Курникова Анна Александровна, 20.03.2022</h2>
          </div>
        </div>
        <div class="eventItem hasViewed">
          <h2 class="eventTitle">Перевод на дистанционное обучение</h2>
          <div class="text">
            <h2 class="itemText">Группа 615 переводится на дистанционное обучение с 26 октября и до окончания цикла 6
              ноября.
              так как 25 октября у студентки Залетовой был получен положительный анализ на COVID-19</h2>
            <h2 class="author">Курникова Анна Александровна, 20.03.2022</h2>
          </div>
        </div>
      </div>
    </kendo-gridlayout-item>
  </kendo-gridlayout>
</div>