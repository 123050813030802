import {ApplicationTabsEnum} from '../enums/application-tabs.enum';
import {ApplicationStatusesEnum} from '../enums/application-statuses.enum';

export interface Tab {
  id?: string;
  name: string;
  selector: ApplicationTabsEnum;
  displayInAddForm: boolean;
  hideOnStatuses?: ApplicationStatusesEnum[];
}

export const tabs: Tab[] = [
  {
    name: 'Лист согласования',
    selector: ApplicationTabsEnum.List,
    displayInAddForm: false,
    hideOnStatuses: [ApplicationStatusesEnum.Draft, ApplicationStatusesEnum.Withdrawn]
  },
  {name: 'Документы на подпись', selector: ApplicationTabsEnum.Documents, displayInAddForm: false},
  {name: 'Документы', selector: ApplicationTabsEnum.ReadyDocuments, displayInAddForm: false},
  {
    name: 'Комментарии',
    selector: ApplicationTabsEnum.Comments,
    displayInAddForm: false,
    hideOnStatuses: [ApplicationStatusesEnum.Draft, ApplicationStatusesEnum.Withdrawn]
  },
];
