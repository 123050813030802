import {Injectable} from '@angular/core';
import {DateFromUTCAsLocal, TimeZoneFix} from '../../helpers/date-helper';
import {KendoProperties} from '../../models/mfc/enums/kendo-properties.enum';
import {
  ApplicationFormPropertyValue, ApplicationPropertyValueDto, ExternalFile,
  FilePropertyValue,
  PropertyValue
} from '../../models/mfc/applicationForm/application-property-value.model';
import {
  ApplicationForm,
  ApplicationFormProperty,
  GroupPropertyForm,
  PropertyForm
} from '../../models/mfc/applicationForm/application-form.model';
import {Property} from '../../models/mfc/applicationConstructor/tabs-settings.model';


@Injectable({
  providedIn: 'root'
})
export class PropertiesService {

  private mapFormPropertyValue(item: PropertyForm): ApplicationFormProperty {
    return {
      applicationConstructorPropertyId: item.externalId,
      propertyType: item.propertyTypeEnum,
      value: item.propertyValue
    };
  }

  private mapPropertyValueForRequest(item: ApplicationFormProperty): ApplicationFormPropertyValue {
    let value;
    switch (item.propertyType) {
      case KendoProperties.Date:
        value = TimeZoneFix(item.value).toISOString();
        break;
      case KendoProperties.File:
      case KendoProperties.Files:
        const files = <File[]|null>item.value;
        value = files?.map((item) => {
          let file: FilePropertyValue = {fileName: item.name};
          if (item?.size) file.file = item;
          return file;
        });
        break;
      default:
        value = item.value;
    }
    return {...item, value: <PropertyValue>value};
  }

  private mapPropertyValuesByType(properties: ApplicationFormProperty[]): ApplicationPropertyValueDto {
    let values: ApplicationPropertyValueDto = {};
    properties.forEach((item) => {
      switch (item.propertyType) {
        case KendoProperties.Boolean:
          if (!values.boolProperties) values.boolProperties = [];
          values.boolProperties.push(item);
          break;
        case KendoProperties.Number:
          if (!values.intProperties) values.intProperties = [];
          values.intProperties.push(item);
          break;
        case KendoProperties.Date:
          if (!values.dateProperties) values.dateProperties = [];
          values.dateProperties.push(item);
          break;
        case KendoProperties.Dropdown:
        case KendoProperties.Combobox:
          if (!values.dictGuidProperties) values.dictGuidProperties = [];
          values.dictGuidProperties.push(item);
          break;
        case KendoProperties.Multiselect:
          if (!values.dictGuidArrayProperties) values.dictGuidArrayProperties = [];
          values.dictGuidArrayProperties.push(item);
          break;
        case KendoProperties.File:
        case KendoProperties.Files:
          if (!values.fileProperties) values.fileProperties = [];
          values.fileProperties.push(item);
          break;
        default:
          if (!values.stringProperties) values.stringProperties = [];
          values.stringProperties.push(item);
      }
    });
    return values;
  }

  private capitalize(key: string) {
    return `${key[0].toUpperCase()}${key.slice(1)}`;
  }

  public valueViewConverter(item: Property) {
    switch (item.propertyTypeEnum) {
      case KendoProperties.Textbox:
      case KendoProperties.Textarea:
        return item.value?.stringValue?.value ?? null;
      case KendoProperties.Number:
        return item.value?.intValue?.value ?? null;
      case KendoProperties.Date:
        return DateFromUTCAsLocal(item.value?.dateValue?.value) ?? null;
      case KendoProperties.Boolean:
        return item.value?.boolValue?.value ?? null;
      case KendoProperties.File:
      case KendoProperties.Files:
        return item.value?.fileValue?.value
          ? item.value?.fileValue?.value.map((item) => {
            const file: ExternalFile = new File([''], item.fileName);
            file.id = item.id;
            return file;
          })
          : null;
      case KendoProperties.Dropdown:
      case KendoProperties.Combobox:
        return item.value?.dictGuidValue?.value ?? null;
      case KendoProperties.Multiselect:
        return item.value?.dictGuidArrayValue?.value.map((item) => item.value) ?? null;
      default:
        return null;
    }
  }

  public mapPropertyValue(item: Property): ApplicationFormProperty {
    return {
      applicationConstructorPropertyId: item.externalId,
      propertyType: item.propertyTypeEnum,
      value: this.valueViewConverter(item)
    };
  }

  public mapFormValues(form: GroupPropertyForm[]): ApplicationFormProperty[] {
    const values: ApplicationFormProperty[] = [];
    form.forEach((group) => {
      group.properties.forEach((property) => {
        values.push(this.mapFormPropertyValue(property));
      });
    });
    return values;
  }

  public getFormData(form: ApplicationForm) {
    form.applicationPropertiesValueDto = form.applicationPropertiesValueDto
      .filter((property) =>
        property.value !== undefined
        && property.value !== null
        && property.value !== ''
        && (!Array.isArray(property.value) || property.value.length))
      .map((property) => this.mapPropertyValueForRequest(property));

    const values = this.mapPropertyValuesByType(form.applicationPropertiesValueDto);

    const data = new FormData();
    let valuesKey = '';
    Object.entries(form).forEach(([key, value]) => {
      if (key !== 'applicationPropertiesValueDto') {
        if (value) data.append(key, value);
      } else {
        valuesKey = this.capitalize(key);
      }
    });

    Object.entries(values).forEach(([key, value]) => {
      (<ApplicationFormProperty[]>value).forEach((item, i) => {
        Object.entries(item).forEach(([itemKey, itemValue]) => {
          const itemKeyName = `${valuesKey}.${this.capitalize(key)}[${i}].${this.capitalize(itemKey)}`;
          if (Array.isArray(itemValue)) {
            itemValue.forEach((arrayValue, j) => {
              if (typeof arrayValue === 'object') {
                Object.entries(arrayValue).forEach(([arrayItemKey, arrayItemValue]) => {
                  const arrayItemKeyName = `${itemKeyName}[${j}].${this.capitalize(arrayItemKey)}`;
                  if (arrayItemValue instanceof File) {
                    data.append(arrayItemKeyName, arrayItemValue, arrayItemValue.name);
                  } else {
                    data.append(arrayItemKeyName, <string>arrayItemValue);
                  }
                });
              } else {
                data.append(`${itemKeyName}[${j}]`, arrayValue);
              }
            });
          } else {
            data.append(itemKeyName, itemValue);
          }
        });
      });
    });
    return data;
  }
}
