export enum StudentFieldCode {
  AchievementsCount = 'Кол-во достижений',
  ActualAddress = 'Фактический адрес проживания',
  ActualBuilding = 'Строение (фактический адрес проживания)',
  ActualCity = 'Населенный пункт (фактический адрес проживания)',
  ActualCorpus = 'Корпус (фактический адрес проживания)',
  ActualCountry = 'Страна (фактический адрес проживания)',
  ActualDistrict = 'Район (фактический адрес проживания)',
  ActualFlat = 'Квартира (фактический адрес проживания)',
  ActualHouse = 'Дом (фактический адрес проживания)',
  ActualState = 'Регион (фактический адрес проживания)',
  ActualStreet = 'Улица (фактический адрес проживания)',
  ActualZipCode = 'Индекс (фактический адрес проживания)',
  Address = 'Адрес по прописке',
  Building = 'Строение (адрес по прописке)',
  City = 'Населенный пункт (адрес по прописке)',
  Corpus = 'Корпус (адрес по прописке)',
  AddressCountry = 'Страна (адрес по прописке)',
  District = 'Район (адрес по прописке)',
  Flat = 'Квартира (адрес по прописке)',
  House = 'Дом (адрес по прописке)',
  State = 'Регион (адрес по прописке)',
  Street = 'Улица (адрес по прописке)',
  ZipCode = 'Индекс (адрес по прописке)',
  AdmissionStudentId = 'Идентификатор студента в ПК',
  Ball = 'Средний балл',
  Benefit = 'Льготы',
  CardReesterNumber = 'Номер реестра выгрузки в банк',
  CitizenshipType = 'Тип гражданства',
  Compatriot = 'Соотечественник',
  CourseNum = 'Курс',
  DateFinish = 'Дата окончания обучения',
  DateStart = 'Дата начала обучения',
  DiplomaNumber = 'Номер диплома выпускника (оригинал)',
  DiplomaRegNumber = 'Рег. номер диплома выпускника (оригинал)',
  DiplomaSeries = 'Серия диплома выпускника (оригинал)',
  DuplicateNumber = 'Номер диплома выпускника (дубликат)',
  DuplicateRegNumber = 'Рег. номер диплома выпускника (дубликат)',
  DuplicateSeries = 'Серия диплома выпускника (дубликат)',
  ApproveVAK = 'Дата утверждения ВАК',
  EducationDocTypeName = 'Тип документа об образовании',
  EducationDocGraduateYear = 'Год получения документа о предыдущем образовании',
  EducationDocIssueDate = 'Дата выдачи документа об образовании',
  EducationDocIssueOrganization = 'Название предыдущего учебного заведения',
  KandidateDisserDate = 'Дата защиты диссертации',
  EducationDocNumber = 'Номер документа об образовании',
  EducationDocSerial = 'Серия документа об образовании',
  EducationDoc = 'Документ об образовании',
  EducationDocStudyLevelName = 'Уровень предыдущего образования',
  EducationProgramName = 'Профиль (программа)',
  EducationStandard = 'Наименование направления подготовки',
  EducationStandardCipher = 'Код направления подготовки',
  EducationStandardName = 'Направление подготовки',
  EnlargedGroupSpecialization = 'Укрупненная группа специальностей',
  FacultyName = 'Институт/факультет',
  FamilyAddress = 'Информация о родственниках (адрес проживания)',
  FamilyMember = 'Информация о родственниках',
  FamilyFio = 'Информация о родственниках (ФИО)',
  FamilyMemberStatus = 'Информация о родственниках (степень родства)',
  FamilyPhoneNumber = 'Информация о родственниках (номер телефона)',
  FamilyState = 'Семейное положение',
  GraduateThis = 'Предыдущее образование получено в этом учебном заведении',
  GroupName = 'Группа',
  Health = 'Группа здоровья',
  Hostel = 'Номер общежития',
  HostelAvailability = 'Проживает общежитии',
  HostelRoom = 'Номер комнаты в общежитии',
  IdentificationBirthPlace = 'Место рождения',
  IdentificationTypeName = 'Тип документа удостоверения личности',
  IdentificationIssueCode = 'Код подразделения документа (удостоверение личности)',
  IdentificationIssueDate = 'Дата выдачи документа (удостоверение личности)',
  IdentificationIssueOrganization = 'Кем выдан документ (удостоверение личности)',
  IdentificationNumber = 'Номер документа (удостоверение личности)',
  IdentificationSerial = 'Серия документа (удостоверение личности)',
  IdentificationString = 'Удостоверение личности',
  IdentificationsVisaDateEnd = 'Срок действия визы',
  Kafedra = 'Кафедра',
  Languages = 'Изучаемые языки',
  MedicalAvailability = 'Наличие медицинской справки',
  MedicalDate = 'Справка о медицинском осмотре действительна до',
  MilitaryComment = 'Дополнительные сведения (ВУС)',
  MilitaryCommissariatAddress = 'Почтовый адрес военного комиссариата',
  CommissariatName = 'Военный комиссариат',
  MilitaryIssue = 'Дата выдачи документа воинского учета',
  IssueMilitaryFinishDate = 'Дата снятия с воинского учета',
  IssueMilitaryStartDate = 'Дата регистрации сведений, направленных в военный комиссариат',
  MilitaryIssueOrganization = 'Кем выдан документ воинского учета',
  MilitaryRankName = 'Воинское звание',
  MilitarySpecialtyNumber = 'Военно-учетная специальность',
  MilitaryStatusName = 'Категория воинского учета',
  MilitaryStockCategoryName = 'Военная категория',
  MilitaryNumber = 'Номер документа воинского учета',
  MilitaryEnlistmentCategory = 'Годность к военной службе',
  MilitaryProfileName = 'Состав (ВУС)',
  MilitaryRegistrationDocSerial = 'Серия документа воинского учета',
  MilitaryStateName = 'Состоит на воинском учете',
  MilitaryEnlistmentStatusName = 'Отношение к военной службе',
  MilitaryOutgoingRegistryNumber = 'Исходящий регистрационный номер сведений, направленных в военный комиссариат',
  MilitaryReasonForDeregistration = 'Причина снятия с воинского учета',
  NeedHostel = 'Необходимость в общежитии',
  OrderFinishOrderDate = 'Приказ об отчислении',
  OrderStart = 'Приказ о зачислении',
  OrderStartDateBegin = 'Приказ о зачислении (начало действия)',
  OrderStartNumber = 'Приказ о зачислении (номер)',
  OrderStartDateEnd = 'Приказ о зачислении (конец действия)',
  OrderStartOrderDate = 'Приказ о зачислении (дата)',
  OrganizationName = 'Место работы',
  Post = 'Должность',
  ScientificPersonName = 'Научный руководитель (куратор)',
  SecondHigherEducation = 'Получает второй высшее',
  SportCategory = 'Спортивный разряд',
  SportType = 'Вид спорта',
  StudentAcademicState = 'Академический статус',
  DictOrganizationEmployerKpp = 'КПП работодателя по договору о целевом обучении',
  DictOrganizationEmployerOgrn = 'ОГРН работодателя по договору о целевом обучении',
  DictOrganizationEmployerOrganizationName = 'Работодатель',
  DictOrganizationInn = 'ИНН заказчика целевого обучения',
  DictOrganizationKpp = 'КПП заказчика целевого обучения',
  DictOrganizationOgrn = 'ОГРН заказчика целевого обучения',
  DictOrganizationName = 'Заказчик целевого обучения',
  PaidContract = 'Договор на платное обучение',
  PaidContractCostFull = 'Стоимость обучения в год, руб.',
  PaidContractCostYear = 'Стоим. в сем., руб',
  PaidContractDate = 'Дата договора на платное обучение',
  PaidContractNum = 'Номер договора на платное обучение',
  StudentBudgetCategoryName = 'Категория финансирования',
  StudentBudgetName = 'Источник финансирования',
  StudentBudgetSubCategoryName = 'Подкатегория финансирования',
  StudentId = '№ п/п',
  StudentNumber = 'Номер зачетной книжки',
  Birthday = 'Дата рождения',
  Country = 'Гражданство',
  CountryCode = 'Код ОКСМ',
  Email = 'Адрес электронной почты',
  FirstName = 'Имя',
  FirstNameLatin = 'Имя (латиница)',
  FullName = 'ФИО',
  FullNameLatin = 'ФИО (латиница)',
  Inn = 'ИНН',
  IsMale = 'Пол',
  LastName = 'Фамилия',
  LastNameLatin = 'Фамилия (латиница)',
  Login = 'Идентификатор студента',
  MaidenName = 'Прежняя фамилия',
  MedicalCertificate = 'Данные о справках',
  MiddleName = 'Отчество',
  MiddleNameLatin = 'Отчество (латиница)',
  Phone = 'Телефон',
  SNILS = 'СНИЛС',
  SocialNetwork = 'Социальная сеть',
  StudyForm = 'Форма обучения',
  StudyLevel = 'Уровень обучения',
  TrainingLevel = 'Уровень подготовки',
  YearFinish = 'Год окончания обучения',
  YearStart = 'Год начала обучения',
  OrdersStudyPeriod = 'Срок обучения'
}
