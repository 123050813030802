import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {ApplicationType} from '../../../models/mfc/dicts/application-type.model';


@Injectable({
  providedIn: 'root'
})
export class ApplicationTypeService {
  private baseUrl = `${environment.apiEndpoint}${environment.mfc.apiPaths.dict}/ApplicationType/`;

  constructor(private http: HttpClient) { }

  public getApplicationTypes(): Observable<ApplicationType[]> {
    return this.http.get<ApplicationType[]>(this.baseUrl);
  }
}
