<kendo-grid class="grid-data"
            [loading]="loading"
            [data]="approvalSettings">
  <kendo-grid-column field="dictRoleId" title="Роль">
    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
      {{ dataItem[column.field]
          ? dictRolesMap.get(dataItem[column.field])
          : dictSignatoryRolesMap.get(dataItem.dictSignatoryRoleId)}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="numberInApprovalSequence" title="Порядок согласования"></kendo-grid-column>
  <kendo-grid-column [width]="200" title="Дата">
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      {{dataItem.completedApprovalStep?.approvalDateTime | date:'dd.MM.yyyy (HH:mm)'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Статус согласования">
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      <span class="status">
        <span class="text">
          <span [ngClass]="{
              name: true,
              red: dataItem.completedApprovalStep && !dataItem.completedApprovalStep.approvalResult,
              yellow: !dataItem.completedApprovalStep,
              green: dataItem.completedApprovalStep && dataItem.completedApprovalStep.approvalResult
            }">
            {{dataItem.completedApprovalStep
              ? dataItem.completedApprovalStep.approvalResult ? dataItem.yesText : dataItem.noText
              : dataItem.notMarkedText
            }}
          </span>
          <span class="info" *ngIf="dataItem.completedApprovalStep">
            ({{dataItem.completedApprovalStep.personName}}, {{dataItem.completedApprovalStep.post}})
          </span>
        </span>
      </span>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
