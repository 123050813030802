<div style="margin: 0 -16px">
  <kendo-tilelayout [resizable]="true" class="paddingFix">
    <kendo-tilelayout-item>
      <kendo-tilelayout-item-body>
        <h2>Образовательная программа</h2>
        <table class="table" style="width: 50%">
          <tr>
            <th>Направление подготовки</th>
            <kendo-loader *ngIf="!hasLoaded" style="position: absolute" type="converging-spinner" size="medium"></kendo-loader>
            <td>{{educationProgramName}}</td>
          </tr>
          <tr *ngIf="hasProfile()">
            <th>Профиль</th>
            <td>{{profileValue}}</td>
          </tr>
          <tr>
            <th>Квалификация</th>
            <td >{{qualificationName}}</td>
          </tr>
          <tr>
            <th>Форма обучения</th>
            <td *ngIf="hasLoaded">{{studyFormValue}}</td>
          </tr>
          <tr>
            <th>Технология обучения</th>
            <td>{{studyTechnologyName}}</td>
          </tr>
          <tr>
            <th>Длительность обучения</th>
            <td>
              <div style="display: inline-flex;">
                <div *ngIf="yearsLength != 0">{{yearsLength}} {{yearConversion[yearsLength] ?? 'лет'}}</div>
                <div *ngIf="monthsLength != 0">&nbsp;</div>
                <div *ngIf="monthsLength != 0">{{monthsLength}} {{monthConversion[monthsLength] ?? 'месяцев'}}</div>
              </div>
            </td>
          </tr>
        </table>
        <kendo-tabstrip class="custom-tabs k-mt-4" [keepTabContent]="true">
          <!-- График учебного процесса -->
          <kendo-tabstrip-tab title="График учебного процесса" [selected]="IsTabSelected(1)">
            <ng-template kendoTabContent>


              <kendo-grid *ngIf="hasLoaded" [data]="tableData" class="table" style="margin-top: 14px">
                <kendo-grid-column
                  [width]="70"
                  [class]="'table__cell'"
                  [headerClass]="'table__header gridHeader'"
                >
                  <ng-template kendoGridHeaderTemplate>
                    <div class="table__header table__header--column">
                      <div class="table__header table__header--title table__header--item">
                        Мес.
                      </div>
                      <div class="table__header table__header--grow table__header--item">
                        Числа
                      </div>
                      <div class="table__header table__header--title table__header--item">
                        Нед.
                      </div>
                    </div>
                  </ng-template>
                  <ng-template kendoGridCellTemplate let-dataItem>
                    {{ dataItem.courseNumber }} курс
                  </ng-template>
                </kendo-grid-column>
                <ng-template ngFor let-column [ngForOf]="tableHeaderData">
                  <kendo-grid-column-group
                    title="{{ dictMonth[column.month] }}"
                    *ngIf="!!column.month"
                    [headerClass]="'table__header table__header--title gridHeader'"
                  >
                    <kendo-grid-column
                      [width]="40"
                      *ngFor="let day of column.weeks"
                      [class]="'table__cell'"
                      [headerClass]="'table__header'"
                    >
                      <ng-template kendoGridHeaderTemplate>
                        <div class="table__subheader">
                          <div class="table__week-header table__week-header--number">
                            {{ day.title }}
                          </div>
                          <div class="table__week-header">{{ day.days }}</div>
                        </div>
                      </ng-template>
                      <ng-template
                        kendoGridCellTemplate
                        let-dataItem
                        let-columnIndex="columnIndex"
                        let-rowIndex="rowIndex"
                      >
                        <educational-process-schedule-cell
                          [weekInfo]="dataItem.weeksNumber[columnIndex - 1]"
                          [currentWeekNumber]="columnIndex"
                          [course]="rowIndex"
                        ></educational-process-schedule-cell>
                      </ng-template>
                    </kendo-grid-column>
                  </kendo-grid-column-group>
                  <kendo-grid-column
                    *ngIf="!column.month"
                    [width]="40"
                    [class]="'table__cell'"
                    [headerClass]="'table__header'"
                  >
                    <ng-template kendoGridHeaderTemplate>
                      <div class="table__subheader table__subheader--long">
                        <div class="table__week-header table__week-header--number">
                          {{ column.title }}
                        </div>
                        <div class="table__week-header">{{ column.days }}</div>
                      </div>
                    </ng-template>
                    <ng-template
                      kendoGridCellTemplate
                      let-dataItem
                      let-columnIndex="columnIndex"
                      let-rowIndex="rowIndex"
                    >
                      <educational-process-schedule-cell
                        [weekInfo]="dataItem.weeksNumber[columnIndex - 1]"
                        [currentWeekNumber]="columnIndex"
                        [course]="rowIndex"
                      ></educational-process-schedule-cell>
                    </ng-template>
                  </kendo-grid-column>
                </ng-template>
              </kendo-grid>

              <!-- Условные обозначения -->
              <h2 *ngIf="hasLoaded">Условные обозначения</h2>
              <kendo-grid *ngIf="hasLoaded" [data]="mapLegend" class="legend">
                <kendo-grid-column headerClass="gridHeader" title="Вид работы" field="typeWork">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="legend__cell">
                      <p style="text-align: left">{{dataItem.name}}</p>
                    </div>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column headerClass="gridHeader" title="Обозначение" field="symbol" [width]=150>
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="legend__cell" style="background-color:{{dataItem.color}}">
                      <p>{{dataItem.symbol}}</p>
                    </div>
                  </ng-template>
                </kendo-grid-column>
              </kendo-grid>
              <div class="doplegend" *ngIf="isInstallationSession">
                <div class="session_text"> Установочная сессия</div>
                <div class="session"></div>
              </div>
            </ng-template>
          </kendo-tabstrip-tab>


          <!-- Базовый учебный план -->
          <kendo-tabstrip-tab title="Базовый учебный план" [selected]="IsTabSelected(2)">
            <ng-template kendoTabContent>
              <kendo-grid *ngIf="hasLoaded" style="margin-top: 14px"
                          [data]="gridData"
                          [height]="500"
                          (cellClick)="disciplineCellClick($event)"
                          [rowClass]="rowClassCallback">
                <kendo-grid-column headerClass="gridHeader" field="Cipher" title="Шифр" [width]="100">
                  <ng-template kendoGridCellTemplate let-dataItem>
                                    <span class="gridCell">
                                        {{dataItem.Cipher}}
                                    </span>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="DictDisciplineName" title="Дисциплина" headerClass="gridHeader">
                  <ng-template kendoGridCellTemplate let-dataItem>
                                    <span class="gridCell">
                                        {{dataItem.DictDisciplineName}}
                                    </span>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="DepartmentName" title="Кафедра" [width]="150" headerClass="gridHeader">
                  <ng-template kendoGridCellTemplate let-dataItem>
                                    <span class="gridCell">
                                        {{dataItem.DepartmentName}}
                                    </span>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="ControllingActions" title="Семестр" [width]="150" headerClass="gridHeader">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <div class="gridCell centerAlignment">
                      <div>
                        {{removeDuplicateSemesters(dataItem.ControllingActions)}}
                      </div>
                    </div>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column-group title="Трудоемкость" headerClass="gridHeader">
                  <kendo-grid-column field="TotalNumberHours" title="Час." [width]="80" format="n0" headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <span class="gridCell centerAlignment">
                                            {{dataItem.TotalNumberHours}}
                                        </span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="TotalLaborIntensity" title="ЗЕД" [width]="80" format="n0" headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <span class="gridCell centerAlignment">
                                            {{dataItem.TotalLaborIntensity}}
                                        </span>
                    </ng-template>
                  </kendo-grid-column>
                </kendo-grid-column-group>
              </kendo-grid>


              <!-- Сводные данные по нагрузке студента -->
              <h2 *ngIf="hasLoaded" style="font-family: Montserrat SemiBold; padding: 8px 0 15px 0">Сводные данные по нагрузке студента</h2>
              <kendo-grid *ngIf="hasLoaded" [data]="totalData">
                <kendo-grid-column field="name" title="Семестр" headerClass="gridHeader" [width]="260">
                  <ng-template kendoGridCellTemplate let-dataItem>
                                        <span class="gridCell centerAlignment">
                                            {{dataItem.name}}
                                        </span>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column headerClass="gridHeader"
                                   *ngFor="let column of totalColumns" field="{{ column.field }}" title="{{ column.title }}">
                  <ng-template kendoGridCellTemplate let-dataItem>
                                        <span class="gridCell centerAlignment">
                                            {{dataItem[column.field] && dataItem[column.field] > 0 ? dataItem[column.field] : null}}
                                        </span>
                  </ng-template>
                </kendo-grid-column>
              </kendo-grid>

              <!-- Сводные данные по бюджету времени -->
              <!--<h2 style="font-family: Montserrat SemiBold; margin: 30px 0 30px 0">Сводные данные по бюджету времени</h2>
              <kendo-grid [data]="budget" scrollable="none">
                  <kendo-grid-column-group headerClass="gridHeader" title="Всего">
                      <kendo-grid-column [width]="100" headerClass="gridHeader" title="Час.">
                          <ng-template kendoGridCellTemplate let-dataItem>
                              <span class="gridCell" style="text-align: center">
                                  {{dataItem.Hours}}
                              </span>
                          </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column [width]="100" headerClass="gridHeader" title="ЗЕД">
                          <ng-template kendoGridCellTemplate let-dataItem>
                              <span class="gridCell" style="text-align: center">
                                  {{dataItem.Intensity | number: '.0-2'}}
                              </span>
                          </ng-template>
                      </kendo-grid-column>
                  </kendo-grid-column-group>
                  <kendo-grid-column-group headerClass="gridHeader" title="Контроль">
                      <kendo-grid-column [width]="100" headerClass="gridHeader" title="Час.">
                          <ng-template kendoGridCellTemplate let-dataItem>
                              <span class="gridCell" style="text-align: center">
                                  {{dataItem.ControlHours}}
                              </span>
                          </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column [width]="100" headerClass="gridHeader" title="ЗЕД">
                          <ng-template kendoGridCellTemplate let-dataItem>
                              <span class="gridCell" style="text-align: center">
                                  {{dataItem.ControlIntensity | number: '.0-2'}}
                              </span>
                          </ng-template>
                      </kendo-grid-column>
                  </kendo-grid-column-group>
                  <kendo-grid-column-group headerClass="gridHeader" title="Ауд. работа">
                      <kendo-grid-column [width]="100" headerClass="gridHeader" title="Час.">
                          <ng-template kendoGridCellTemplate let-dataItem>
                              <span class="gridCell" style="text-align: center">
                                  {{dataItem.ContactWorkHours}}
                              </span>
                          </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column [width]="100" headerClass="gridHeader" title="ЗЕД">
                          <ng-template kendoGridCellTemplate let-dataItem>
                              <span class="gridCell" style="text-align: center">
                                  {{dataItem.ContactWorkIntensity | number: '.0-2'}}
                              </span>
                          </ng-template>
                      </kendo-grid-column>
                  </kendo-grid-column-group>
                  <kendo-grid-column-group headerClass="gridHeader" *ngFor="let item of budgetTimesData" [title]="item.name">
                      <kendo-grid-column headerClass="gridHeader" title="Час.">
                          <ng-template kendoGridCellTemplate>
                              <span class="gridCell" style="text-align: center">
                                  {{item.value}}
                              </span>
                          </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column headerClass="gridHeader" title="ЗЕД">
                          <ng-template kendoGridCellTemplate>
                              <span class="gridCell" style="text-align: center">
                                  {{item.value / IntensityMeasure | number: '.0-2'}}
                              </span>
                          </ng-template>
                      </kendo-grid-column>
                  </kendo-grid-column-group>
                <kendo-grid-column-group headerClass="gridHeader" title="Сам. работа">
                  <kendo-grid-column [width]="100" headerClass="gridHeader" title="Час.">
                    <ng-template kendoGridCellTemplate let-dataItem>
                              <span class="gridCell" style="text-align: center">
                                  {{dataItem.SelfWorkHours}}
                              </span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column [width]="100" headerClass="gridHeader" title="ЗЕД">
                    <ng-template kendoGridCellTemplate let-dataItem>
                              <span class="gridCell" style="text-align: center">
                                  {{dataItem.SelfWorkIntensity | number: '.0-2'}}
                              </span>
                    </ng-template>
                  </kendo-grid-column>
                </kendo-grid-column-group>
              </kendo-grid>-->
            </ng-template>
          </kendo-tabstrip-tab>

          <!-- Матрица компетенций -->

          <kendo-tabstrip-tab title="Матрица компетенций" [selected]="IsTabSelected(3)">
            <ng-template kendoTabContent>
              <kendo-grid *ngIf="hasLoaded"
                style="margin-top: 14px"
                (cellClick)="competenceMatrixCellClick($event)"
                [data]="bupData"
                [height]="500">
                <kendo-grid-column headerClass="gridHeader" field="Cipher" title="Шифр" [width]="150">
                  <ng-template kendoGridCellTemplate let-dataItem>
                                    <span class="gridCell">
                                        {{dataItem.Cipher}}
                                    </span>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column  field="DictCycleName" title="Цикл" [hidden]="true" headerClass="gridHeader">
                </kendo-grid-column>
                <kendo-grid-column field="DictComponentName" title="Компонент" [hidden]="true" headerClass="gridHeader">
                  <ng-template kendoGridCellTemplate let-dataItem>
                                    <span class="gridCell">
                                        {{dataItem.DictComponentName}}
                                    </span>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="DictDisciplineName" title="Дисциплина" headerClass="gridHeader">
                  <ng-template kendoGridCellTemplate let-dataItem>
                                    <span class="gridCell">
                                        {{dataItem.DictDisciplineName}}
                                    </span>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="TotalLaborIntensity" title="Трудоемкость" headerClass="gridHeader">
                  <ng-template kendoGridCellTemplate let-dataItem>
                                    <span class="gridCell" style="text-align: center">
                                        {{dataItem.TotalLaborIntensity}}
                                    </span>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="DistributionCompetences" title="Компетенции" headerClass="gridHeader" [width]="400">
                  <ng-template kendoGridCellTemplate let-dataItem>
                                    <span (click)="navigateToCompetenceMatrix()" class="gridCell competenceMatrix" style="text-align: center; cursor: pointer">
                                        {{displayCompetenceMatrix(dataItem.DistributionCompetences)}}
                                    </span>
                  </ng-template>
                </kendo-grid-column>
              </kendo-grid>
            </ng-template>
          </kendo-tabstrip-tab>
        </kendo-tabstrip>
      </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>
  </kendo-tilelayout>
</div>
