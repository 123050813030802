import {Injectable} from "@angular/core";
import {BehaviorSubject} from 'rxjs';
import {PropertiesService} from './properties.service';
import {ApplicationFormProperty, ApplicationTabData} from '../../models/mfc/applicationForm/application-form.model';
import {TabsSettings} from '../../models/mfc/applicationConstructor/tabs-settings.model';
import {ApplicationApprovalSettings} from '../../models/mfc/applicationForm/approvalList.model';


@Injectable({
  providedIn: 'root'
})
export class ApplicationEditFormService {

  private tabsData: ApplicationTabData[] = [];
  private hasChanges: boolean = false;

  public save$ = new BehaviorSubject<number|null>(null);
  public checkChanges$ = new BehaviorSubject<number|null>(null);

  constructor(
    private propertiesService: PropertiesService
  ) { }

  private mapTabsValues(tabs: TabsSettings[], startIndex: number): ApplicationTabData[] {
    const data: ApplicationTabData[] = [];
    tabs.forEach((tab, index) => {
      const values: ApplicationFormProperty[] = [];
      tab.groupProperties.forEach((group) => {
        group.standardProperties.forEach((property) => {
          values.push(this.propertiesService.mapPropertyValue(property));
        });
        group.additionalProperties.forEach((property) => {
          values.push(this.propertiesService.mapPropertyValue(property));
        });
      });
      data.push({index: index + startIndex, applicationPropertiesValueDto: values});
    });
    return data;
  }

  public setTabsData(tabs: TabsSettings[], startIndex: number) {
    this.tabsData = this.mapTabsValues(tabs, startIndex);
  }

  public getTabsValues(): ApplicationFormProperty[] {
    return this.tabsData.map((item) => item.applicationPropertiesValueDto).flat();
  }

  public saveTabData(index: number, data: ApplicationFormProperty[]) {
    const tabIndex = this.tabsData.findIndex((item) => item.index === index);
    if (tabIndex >= 0) {
      this.tabsData[tabIndex].applicationPropertiesValueDto = data;
    } else {
      this.tabsData.push({index, applicationPropertiesValueDto: data});
    }
  }

  public getTabValues(index: number) {
    return this.tabsData.find((item) => item.index === index)?.applicationPropertiesValueDto;
  }

  public clearTabsData() {
    this.tabsData = [];
  }

  public setHasChanges(value: boolean) {
    this.hasChanges = this.hasChanges || value;
  }

  public resetHasChanges() {
    this.hasChanges = false;
  }

  public hasFormChanges(index: number) {
    this.checkChanges$.next(index);
    return this.hasChanges;
  }

  public mapApprovalSettings(settings: ApplicationApprovalSettings[]) {
    let index = settings.map(a =>
      a.completedApprovalStep?.approvalResult).lastIndexOf(true);

    if (index < 0) {
      index = 0;
    }

    let currentStage = settings[index].numberInApprovalSequence;

    const allApproved = settings.filter(a => a.numberInApprovalSequence
      === currentStage && !a.completedApprovalStep?.approvalResult).length === 0;

    if (allApproved) {
      currentStage++;
    }

    settings.forEach((item) => {
      item.isCurrentStage = (item.numberInApprovalSequence === currentStage && !item.completedApprovalStep?.approvalResult);
    });

    return settings;
  }
}
