import {Directive, Input} from '@angular/core';

@Directive({
  selector: 'ng-template[typedTemplate]'
})
export class TypedTemplateDirective<TypeToken> {

  @Input('typedTemplate')
  typeToken?: TypeToken;

  static ngTemplateContextGuard<TypeToken>(
    _dir: TypedTemplateDirective<TypeToken>,
    _ctx: unknown
  ): _ctx is TypeToken {
    return true;
  }
}