import {ApplicationStatusesEnum} from '../enums/application-statuses.enum';
import {Dict} from '../dict.model';
import {Moment} from 'moment';

export interface ApplicationList {
  applications: Application[];
  totalApplicationCount: number;
}

export interface Application {
  id: string;
  number: string;
  createdAt: Date;
  category: Dict;
  type: Dict;
  status: ApplicationStatusesEnum;
  statusDate: Date;
  notificationCount: number;
}

export interface QueryOptions {
  page: Page;
  filter: Filter;
  sortParameters: Sort|null;
  // sortParameters: Sort[]|null; //TODO
}

export interface Page {
  number: number;
  size: number;
}

export interface Filter {
  [key: string]: unknown;
  number: string|null;
  createdAt: DateRange|null;
  category: string[]|null;
  type: string[]|null;
  statuses: ApplicationStatusesEnum[]|null;
  statusDate: DateRange|null;
}

export type SortMode = 'ASC' | 'DESC';

export const sortModeValues = new Map<string, SortMode>([
  ['asc', 'ASC'],
  ['desc', 'DESC'],
]);

export interface Sort {
  columnName: string;
  mode: SortMode;
}

export interface DateRange {
  start?: Date|Moment;
  end?: Date|Moment;
}
