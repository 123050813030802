import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CommentsService} from "../../../../services/mfc/comments.service";
import {Comment, CommentFile, CommentRequest} from "../../../../models/mfc/applicationForm/comment.model";
import {ActivatedRoute} from "@angular/router";
import {saveAs} from "@progress/kendo-file-saver";

@Component({
  selector: 'mfc-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class ApplicationFormCommentsComponent implements OnInit {

  protected comments: Comment[] = [];

  private applicationFormId: string = this.activatedRoute.snapshot.paramMap.get('id') ?? '';
  protected filesValid = true;

  protected formGroup = new FormGroup({
    applicationId: new FormControl(this.applicationFormId, {nonNullable: true, validators: Validators.required}),
    text: new FormControl('', {nonNullable: true, validators: Validators.required}),
    files: new FormControl([], {nonNullable: true})
  })

  protected readonly allowedExtensions = ['.docx', '.doc', '.pdf', '.gif', '.jpg', '.png', '.tif', '.xls', '.xlsx'];
  protected readonly fileRestrictions = {
    allowedExtensions: this.allowedExtensions,
    accept: this.allowedExtensions.join(', '),
    maxCount: 10
  };
  protected readonly fileNotes = `До ${this.fileRestrictions.maxCount} файлов форматов ${this.fileRestrictions.accept}`;

  constructor(
    private commentsService: CommentsService,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.getComments();
  }

  private getComments() {
    this.commentsService.getComments(this.applicationFormId).subscribe(data => {
      this.comments = data;
      this.cdr.detectChanges();
    });
  }

  protected onSave() {
    const formData = this.getFormData(this.formGroup.getRawValue());
    this.commentsService.addComment(formData).subscribe(() => {
      this.formGroup.reset();
      this.getComments();
    });
  }

  private getFormData(body: CommentRequest) {
    const data = new FormData();
    Object.entries(body).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.filter(a => a instanceof File).forEach((item) => {
          data.append(`files`, item);
        });
      } else {
        data.append(key, value);
      }
    });
    return data;
  }

  protected deleteComment(comment: Comment) {
    this.commentsService.deleteComment(comment.id).subscribe(() => this.getComments());
  }

  protected getFile(commentId: string, file: CommentFile) {
    this.commentsService.getCommentFile(commentId, file.id).subscribe((response) =>
      saveAs(response, file.fileName)
    );
  }

  protected filesChanged(files: File[]) {
    this.filesValid = files ? files.length < this.fileRestrictions.maxCount : true;
  }
}
