<div class="container">
  <h1 class="portfolio__name">Портфолио</h1>
  <div *ngIf="!editMode">
    <button kendoButton class="addButton bottom" iconClass="k-icon k-i-plus"
            *ngIf='editable'
            (click)="addAchievement()">
      Добавить
    </button>

    <h6 style="font-size: 14px;" *ngIf="achievements.length == 0">Нет загруженных достижений</h6>
    <kendo-listview containerClass="k-d-flex k-flex-col k-flex-nowrap"
                    style="border-style: none"
                    *ngIf="achievements.length > 0"
                    [data]="achievements"
                    [loading]='loading'>
      <ng-template kendoListViewLoaderTemplate>
        <div class="k-loading-color"></div>
        <div class="loading-container">
          <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
      </ng-template>
      <ng-template kendoListViewItemTemplate let-dataItem="dataItem">
        <h2 class="portfolio__name">
          {{displayDirectionActivityName(dataItem.directionActivityId)}}
        </h2>
        <kendo-grid class="portfolio-contingent-grid" scrollable="none"
                    [data]="dataItem.data"
                    (remove)="removeAchievement($event)"
                    (edit)="editAchievement($event)">
          <kendo-grid-column headerClass="gridHeader" field="name" title="Название работы/мероприятия/достижения"
                             [headerStyle]="{'text-align': 'center'}">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span class="alignCells">{{dataItem.name}}</span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column headerClass="gridHeader dictionaries" field="lastEdit" title="Последнее изменение"
                             [width]="250">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span class="alignCells">
                {{(dataItem?.updateAt == null ? "" : dataItem.updateAt| date: "dd.MM.yy HH:mm")}}
              </span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column headerClass="gridHeader" field="review" title="Сканированная копия"
                             [width]="250"
                             [headerStyle]="{'text-align': 'center'}">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class='k-text-center'>
                <span style="cursor: pointer; margin: 0 auto;"
                      class="k-icon k-i-file"
                      *ngFor='let file of dataItem.files'
                      (click)="downloadFile(file)">
                </span>
              </div>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column title="" [headerStyle]="{'text-align': 'center'}"
                             [width]="140" [hidden]='!editable'>
            <ng-template kendoGridCellTemplate let-dataItem>
              <button kendoGridEditCommand *ngIf="dataItem.studentAllowedEdit"
                      themeColor="secondary" icon="edit" fillMode="flat" size="medium" rounded="full">
              </button>
              <button kendoGridRemoveCommand *ngIf="dataItem.studentAllowedEdit"
                      themeColor="secondary" icon="delete" fillMode="flat" size="medium" rounded="full">
              </button>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </ng-template>
    </kendo-listview>
  </div>
  <div class="edit-form" *ngIf="editMode">
    <form novalidate class="k-form" [formGroup]="editForm">
      <kendo-formfield>
        <div class="portfolioField">
          <h2 class="portfolioField__title">Название деятельности</h2>
          <kendo-dropdownlist textField="name" valueField="id" required
                              formControlName="directionActivityId"
                              [data]="directionActivities"
                              [valuePrimitive]="true">
          </kendo-dropdownlist>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="portfolioField">
          <h2 class="portfolioField__title">Название работы/мероприятия/достижения</h2>
          <kendo-textbox formControlName="name" required
                         [maxlength]="250"
          ></kendo-textbox>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="portfolioField">
          <h2 class="portfolioField__title">Сканированная копия</h2>
          <kendo-fileselect style="width: 100%"
                            [restrictions]="restrictions"
                            [multiple]="false"
                            [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="fileUploads"
                            (select)="fileSelected()"
                            (remove)="fileRemoved()">
            <kendo-fileselect-messages
              invalidFileExtension="Недопустимый формат файла. Используйте только .pdf файлы"
              [invalidMaxFileSize]="maxFileSizeMessage">
            </kendo-fileselect-messages>
          </kendo-fileselect>
        </div>
        <span class="k-display-flex k-align-items-center"
              *ngFor='let file of fileUploadedList'
              (click)="downloadFile(file)">
            <span class="k-icon k-i-file"></span>
            <h2 class="uploadedFile__name">{{file.name}}</h2>
        </span>
      </kendo-formfield>
      <!--div *ngIf="checked" class="conditionCheckbox">
        <input type="checkbox" formControlName="meetsScholarshipRequirement" #meetsScholarshipRequirement />
        <h3 class="conditionTitle">Соответствует критерию из стипендиального положения</h3>
      </div-->
      <h2 class="warning" *ngIf="!fileUploaded">* Загрузите скан.копию для добавления нового достижения</h2>
      <div class="buttons">
        <button kendoButton [primary]="true" class="cancelButton" (click)="closeEditForm()">Отмена</button>
        <button kendoButton themeColor="primary" class="saveButton"
                *ngIf="fileUploaded && editForm.valid"
                (click)="saveAchievement()">
          Сохранить
        </button>
      </div>
    </form>
  </div>
</div>

<div kendoDialogContainer></div>
