import * as moment from 'moment';
import {Moment} from 'moment';
import {DatePipe} from '@angular/common';

//нужна для получения данных с сервера, чтобы не съехало время из-за часового пояса
export function DateFromUTCAsLocal(date: any): Date {
  if(date == null) return date;
  return new Date(typeof date == 'string' ? `${date}`.replace('Z','') : date)
}

//для отправки данных на сервер, чтобы время ушло как UTC
export function TimeZoneFix(date: any, isStringDate?: boolean): Moment {
  return isStringDate ? moment(date, 'YYYY-MM-DD').utc(true) : moment(date).utc(true);
}

// Конвертирование даты в строку
export function DateToString(date: Date, format?: string) {
  const datepipe: DatePipe = new DatePipe('ru-Ru');
  return datepipe.transform(date, format != null ? format:'dd.MM.yyyy');
}
