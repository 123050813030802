import {Component, OnInit} from '@angular/core';
import { LKStudent } from 'src/app/models/profile/lkstudent.model';
import { LKStudentService } from 'src/app/services/LKStudent/lkstudent.service';
import {AlignSettings} from "@progress/kendo-angular-layout/layouts/models";
import { LKStudPerson } from 'src/app/models/profile/lkStudPerson.model';
import { LKEmail } from 'src/app/models/profile/lkEmail.model';
import { LKLanguages } from 'src/app/models/profile/lkLanguages.model';
import { LKOrdersStudyPeriod } from 'src/app/models/profile/lkOrdersStudyPeriod.model';
import { LKPhone } from 'src/app/models/profile/lkPhone.model';
import { LKStudEduGroup } from 'src/app/models/profile/lkStudEduGroup.model';
import { LKEduGroup } from 'src/app/models/profile/lkEduGroup.model';
import { LKDictOrganization } from 'src/app/models/profile/dictionaries/lkDictOrganization.model';
import { LKDictStudentAcademicState } from 'src/app/models/profile/dictionaries/lkDictStudentAcademicState.model';
import { LKDictStudentBudgetSubCategory } from 'src/app/models/profile/dictionaries/lkDictStudentBudgetSubCategory.model';
import { LKDictStudentBudget } from 'src/app/models/profile/dictionaries/lkDictStudentBudget.model';
import { LKStudentAcademicState } from 'src/app/models/profile/lkStudentAcademicState.model';
import { LKStudentBudgetState } from 'src/app/models/profile/lkStudentBudgetState.model';
import { LKEmailService } from 'src/app/services/LKStudent/lkemail.service';
import { LKLanguagesService } from 'src/app/services/LKStudent/lklanguages.service';
import { LKOrdersStudyPeriodService } from 'src/app/services/LKStudent/lkorders-study-period.service';
import { LKPhoneService } from 'src/app/services/LKStudent/lkphone.service';
import { LKStudEduGroupService } from 'src/app/services/LKStudent/lkstud-edu-group.service';
import { LKStudentAcademicStateService } from 'src/app/services/LKStudent/lkstudent-academic-state.service';
import { LKStudentBudgetStateService } from 'src/app/services/LKStudent/lkstudent-budget-state.service';
import { LKDictLanguages } from 'src/app/models/profile/dictionaries/LkDictLanguages.model';
import { LKDictLanguageLevels } from 'src/app/models/profile/dictionaries/lkDictLanguageLevels.model';
import { LKEducationPlan } from 'src/app/models/education/LKEducationPlan.model';
import { Department } from 'src/app/models/profile/external/department.model';
import { Faculty } from 'src/app/models/profile/external/faculty.model';
import { LKEducationProgram } from 'src/app/models/education/LKEducationProgram.model';
import { EducationStandard } from 'src/app/models/education/EducationStandard.model';
import { DictStudyForm } from 'src/app/models/profile/dictionaries/DictStudyForm.model';
import { LKStudPlanService } from 'src/app/services/LKStudent/lkstud-plan.service';
import { LKStudPlan } from 'src/app/models/education/LKStudPlan.model';
import { DictQualification } from 'src/app/models/education/DictQualification.model';
import {environment} from "../../../environments/environment";
@Component({
  selector: 'client-apps-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

  public hasLoaded = false;
  public photoUrl = '';
  public supportEmail = environment.supportEmail;
  public infoMessage = environment.infoMessage;
  public hidden = true;
  ///////// Переменные профиля: /////////////

  // ФИО
  public fullName? = "-"
  // Номер студента
  public studentNumberValue? = "-"
  // Источник финансирования
  public budgetStateValue? = "-"
  // Форма обучения
  public studyFormValue? = "-"
  // Факультет (институт)
  public facultyValue? = "-"
  // Курс
  public courseValue? = "-"
  // Группа
  public groupNameValue? = "-"
  // Направление обучения
  public studyDirection? = "-"
  // Направленность (профиль)
  public hasProfile():boolean { return (this.educationProgram?.profilingCourseNumber ?? 1) <= (this.studEduGroup?.eduGroup?.courseNum ?? 1)}
  public profile? = "-";
  // Кафедра
  public departmentValue? = "-"
  // Академический статус
  public academicStatusValue? = "-"
  // Заказчик целевого обучения
  public organizationValue? = "-"
  public hideOrganizationValue = true;
  // Изучаемые языки
  public languagesValue? = "-"
  // Средний балл
  public markAverageValue? = "-"
  // Дата начала обучения
  public dateStartValue? = "-"
  // Год выпуска
  public dateEndValue? = "-"
  // Email
  public emailValue? = "-"
  // Телефон
  public phoneValue? = "-"

  ///////////////////////////////////////////

  // Для проверки планов из группы и из студента
  public groupEducationPlan? = ""
  public studEducationPlan? = ""
  public departmentStudPlan? = ""

  // Выставить true после появления расписания занятий
  public shortInformation = false;
  public align: AlignSettings = {vertical: "top"}
  public currentDate = new Date();
  public dayName = "";

  constructor(
    private studentService: LKStudentService,
    private emailService: LKEmailService,
    private languagesService: LKLanguagesService,
    private ordersStudyPeriodService: LKOrdersStudyPeriodService,
    private phoneService: LKPhoneService,
    private studEduGroupService: LKStudEduGroupService,
    private studentAcademicStateService: LKStudentAcademicStateService,
    private studentBudgetStateService: LKStudentBudgetStateService,
    private studPlanService: LKStudPlanService) {
      // Получить текущее название дня недели
      let daysArray = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];
      let day = new Date().getDay();
      this.dayName = daysArray[day]
    }

  public gridData = [
    { channel: "Работников всего", conversion: 8232, users: 1539 },
    { channel: "ППС", conversion: 6574, users: 935 },
    { channel: "АУП", conversion: 4932, users: 150 },
    { channel: "Прочие", conversion: 1172, users: 454 },
  ];
  public eventsData = [
    { id: 1, title: "LIST FOR THE ALIMENTARY CONTROL ON PREPARATIONS", author: "Курникова Анна Александровна", date: "20.03.2022", annotation: "List for the alimentary control on preparations" },
    { id: 2, title: "LIST FOR THE ALIMENTARY CONTROL ON PREPARATIONS", author: "Курникова Анна Александровна", date: "20.03.2022", annotation: "List for the alimentary control on preparations" },
    { id: 3, title: "LIST FOR THE ALIMENTARY CONTROL ON PREPARATIONS", author: "Курникова Анна Александровна", date: "20.03.2022", annotation: "List for the alimentary control on preparations" },
    { id: 4, title: "LIST FOR THE ALIMENTARY CONTROL ON PREPARATIONS", author: "Курникова Анна Александровна", date: "20.03.2022", annotation: "List for the alimentary control on preparations" },
  ];

  public examsData = [
    { id: 1, subject: "Психологическое сопровождение пациентов в общей медицинской практике", author: "Курникова Анна Александровна",
    date: "20.03.2022", location: "List for the alimentary control on preparations" },
    { id: 2, subject: "Психология, психопатология и психотерапия зависимостей", author: "Курникова Анна Александровна",
    date: "20.03.2022", location: "List for the alimentary control on preparations" },
    { id: 3, subject: "Типология личностных расстройств", author: "Курникова Анна Александровна",
    date: "20.03.2022", location: "List for the alimentary control on preparations" },
  ];

  public studyForm: DictStudyForm = {
    studyFormName: '',
    studyFormSName: ''
  }

  public faculty: Faculty = {
    name: '-',
    shortName: '-'
  }

  public department: Department = {
    name: '-',
    shortName: '-',
    faculty: this.faculty
  }

  public educationStandard: EducationStandard = {
    cipher: '',
    name: '',
    shortName: '',
    dictStandardTypeId: '',
    dictEducationLevelId: '',
    dictTrainingLevelId: ''
  }

  public dictQualification: DictQualification = {
    qualificationName: '',
    qualificationSName: ''
  }

  public educationPrograms: LKEducationProgram[] = [];
  public educationProgram: LKEducationProgram = {
    lkEducationProgramId: 0,
    lkEducationProgramExternalId: '',
    cipher: '',
    name: '',
    shortname: '',
    educationStandardId: '',
    educationStandard: this.educationStandard,
    dictQualificationId: '',
    dictQualification: this.dictQualification,
    departmentId: '',
    graduatingDepartment: this.department,
    faculty: this.faculty
  }

  public educationPlans: LKEducationPlan[] = [];
  public educationPlan: LKEducationPlan = {
    educationPlanId: 0,
    educationPlanExternalId: "",
    jsonData: "",
    educationProgramId: '',
    educationProgram: this.educationProgram,
    dictStudyFormId: '',
    dictStudyForm: this.studyForm
  }


  // StudPerson
  public studPersons: LKStudPerson[] = [];
  public studPerson: LKStudPerson = {
    firstName: '',
    lastName: '',
    middleName: '',
    isMale: false,
    birthday: new Date()
  }

  // Student
  public students: LKStudent[] = [];
  public studentModel: LKStudent = {
    externalId: "",
    studentNumber: "-",
    studPersonId: "",
    studPerson: this.studPerson
  }

  public eduGroup?: LKEduGroup = {
    courseNum: 0,
    groupName: "-",
    faculty: this.faculty,
    facultyId: ""
  }

  public dictOrganization: LKDictOrganization = {
    organizationName: ''
  }

  public dictStudentAcademicState: LKDictStudentAcademicState = {
    academicStateName: '-'
  }

  public dictStudentBudget?: LKDictStudentBudget = {
    studentBudgetName: '-',
    studentBudgetSName: '-',
    isBudget: false
  }

  public dictStudentBudgetSubCategory: LKDictStudentBudgetSubCategory = {
    dictStudentBudgetId: '',
    dictStudentBudget: this.dictStudentBudget,
    budgetSubCategoryName: '-'
  }

  public studentAcademicState: LKStudentAcademicState = {
    studentId: '',
    dictStudentAcademicStateId: '',
    dictStudentAcademicState: this.dictStudentAcademicState
  }

  public studentBudgetState: LKStudentBudgetState = {
    studentId: '',
    dictStudentBudgetId: '',
    dictStudentBudget: this.dictStudentBudget,
    dictOrganization: this.dictOrganization,
    dictStudentBudgetSubCategory: this.dictStudentBudgetSubCategory
  }

  public studEduGroup?: LKStudEduGroup = {
    studentId: '',
    eduGroupId: '',
    studPersonId: '',
    fullName: '',
    eduGroup: this.eduGroup
  }

  public phone?: LKPhone = {
    studPersonId: '',
    phoneNumber: '-'
  }

  public orderStudyPeriod: LKOrdersStudyPeriod = {
    studentId: '',
    dateStart: new Date(),
    dateFinish: new Date()
  }

  public dictLanguageLevel?: LKDictLanguageLevels = {
    levelName: '-'
  }

  public dictLanguage?: LKDictLanguages = {
    languageName: '-'
  }

  public languages: LKLanguages[] = [];
  public language?: LKLanguages = {
    studPersonId: '',
    dictLanguageId: '',
    dictLanguage: this.dictLanguage,
    dictLanguageLevelId: '',
    dictLanguageLevel: this.dictLanguageLevel
  }

  public email?: LKEmail = {
    studPersonId: '',
    email: '-'
  }

  public studPlan?: LKStudPlan = {
    studentId: '',
    educationPlan: this.educationPlan,
    department: this.department
  }

    public getCurrentStudent() {
      const changeStudent = Number(localStorage.getItem('changeStudent'));
        this.studentService.getCurrentStudent()
            .subscribe(
                async response => {
                    this.students = response;
                    if(changeStudent) {
                        this.studentModel = this.students[changeStudent-1];
                    }
                    else {
                        this.studentModel = this.students[0];
                    }
                    this.studentNumberValue = this.studentModel.studentNumber;
                    this.fullName = this.studentModel.studPerson.lastName + " " + this.studentModel.studPerson.firstName + " " + this.studentModel.studPerson.middleName;
                    this.photoUrl = `url('${environment.apiEndpoint}studperson/GetStudPersonPhoto/${this.studentModel.studPersonId}')`;
                    //console.log(this.studentModel);
                    this.getEmail(`${this.studentModel.studPersonId}`);
                    this.getPhones(`${this.studentModel.studPersonId}`);
                    this.getLanguages(`${this.studentModel.studPersonId}`);
                    this.getStudentAcademicStatus(`${this.studentModel.externalId}`);
                    this.getOrdersStudyPeriod(`${this.studentModel.externalId}`);
                    this.getStudEduGroup(`${this.studentModel.externalId}`);
                    this.getStudentBudgetState(`${this.studentModel.externalId}`);
                    //this.getEducationPlanByStudentId(`${this.studentModel.externalId}`);
                    this.getEducationProgramByStudentId(`${this.studentModel.externalId}`);
                    this.getStudyFormByStudentId(`${this.studentModel.externalId}`);
                    this.getStudPlan(`${this.studentModel.externalId}`);
                }
            );
    }

  public getEmail(studPersonId: string) {
    this.emailService.getEmails(studPersonId)
    .subscribe(
      response => {
        this.email = response;
        if(this.email?.email != null || this.email?.email != undefined )
        this.emailValue = this.email?.email;
      }
    );
  }

  public getStudEduGroup(studentId: string) {
    this.studEduGroupService.getStudEduGroup(studentId)
    .subscribe(
      response => {
        this.studEduGroup = response;
        this.facultyValue = this.studEduGroup?.eduGroup?.faculty?.name;
        this.courseValue = this.studEduGroup?.eduGroup?.courseNum.toString();
        this.groupNameValue = this.studEduGroup?.eduGroup?.groupName;
        this.groupEducationPlan = this.studEduGroup?.eduGroup?.planId;
        //console.log(this.studEduGroup.eduGroup);
      }
    );
  }

  public getPhones(studPersonId: string) {
    this.phoneService.getPhones(studPersonId)
    .subscribe(
      response => {
        this.phone = response;
        if(this.phone?.phoneNumber != null || this.phone?.phoneNumber != undefined)
          this.phoneValue = this.phone?.phoneNumber;
      }
    );
  }

  public getOrdersStudyPeriod(studentId: string) {
    this.ordersStudyPeriodService.getOrdersStudyPeriods(studentId)
    .subscribe(
      response => {
        this.orderStudyPeriod = response;
        if(this.orderStudyPeriod !== null) {
          const endDateParse = new Date(this.orderStudyPeriod.dateFinish);
          const startDateParse = new Date(this.orderStudyPeriod.dateStart);
          const endYear = endDateParse.getFullYear().toString();
          const startYear = startDateParse.getFullYear().toString();
          if(endYear !== '1')
            this.dateEndValue = endYear;
          if(startYear !== '1')
            this.dateStartValue = startYear;
        }
        //console.log("Orders:", this.orderStudyPeriod);
      }
    );
  }

  public getLanguages(studPersonId: string) {
    this.languagesService.getLanguages(studPersonId)
    .subscribe(
      response => {
        this.languages = response;
        this.languagesValue = "";
        for(let item of this.languages) {
          if(item?.dictLanguage?.languageName != null) {
            this.languagesValue += item?.dictLanguage?.languageName + ", ";
          }
        }
        this.languagesValue = this.languagesValue.slice(0, -2);
        //console.log(this.languages);
      }
    );
  }

  public getStudentAcademicStatus(studentId: string) {
    this.studentAcademicStateService.getStudentAcademicStates(studentId)
    .subscribe(
      response => {
        this.studentAcademicState = response;
        if(this.studentAcademicState?.dictStudentAcademicState?.academicStateName != null) {
          this.academicStatusValue = this.studentAcademicState?.dictStudentAcademicState?.academicStateName;
        }
      }
    );
  }

  public getStudentBudgetState(studentId: string) {
    this.studentBudgetStateService.getStudentBudgetStates(studentId)
    .subscribe(
      response => {
        this.studentBudgetState = response;
        if(this.studentBudgetState?.dictStudentBudget?.studentBudgetName) {
          this.budgetStateValue = this.studentBudgetState?.dictStudentBudget?.studentBudgetName;
        }
        if(this.studentBudgetState?.dictOrganization?.organizationName) {
          this.organizationValue = this.studentBudgetState?.dictOrganization?.organizationName;
          this.hideOrganizationValue = false;
        }
      }
    );
  }

  public getEducationProgramByStudentId(studentId: string) {
    this.studEduGroupService.getEducationProgramByStudentId(studentId)
      .subscribe(
        response => {
          this.educationProgram = response;
          this.studyDirection = this.educationProgram?.educationStandard != null
            ? (this.educationProgram?.educationStandard?.cipher + " - " + this.educationProgram?.educationStandard?.name)
            : "-";
          this.profile = this.educationProgram?.name;
          if(this.profile === undefined)
          {
            this.profile = "-"
          }
        }
      )
  }

  public getStudyFormByStudentId(studentId: string) {
    this.studEduGroupService.getStudyFormByStudentId(studentId)
      .subscribe(
        response => {
          this.studyForm = response;
          this.studyFormValue = this.studyForm?.studyFormName;
        }
      )
  }

  public getStudPlan(studentId: string) {
    this.studPlanService.getStudPlan(studentId)
    .subscribe(
      async response => {
        this.studPlan = response;
        this.departmentStudPlan = this.studPlan?.departmentId;
        this.studEducationPlan = this.studPlan?.planId;
        await delay(500);
        this.hasLoaded = true;

        // Check if studplan is available
        // Есть DepartmentId в StudPlan
        if (this.departmentStudPlan != null || this.departmentStudPlan != undefined) {
          this.departmentValue = this.studPlan?.department?.name;
        }
        // Есть EducationPlan в StudPlan
        else if (this.studEducationPlan != null || this.studEducationPlan != undefined) {
          this.departmentValue = this.studPlan?.educationPlan?.educationProgram?.graduatingDepartment?.name
        }
        // Взять из плана группы
        else {
          this.departmentValue = this.educationProgram?.graduatingDepartment?.name;
        }
      }
    )
  }

  public showStudentInformation() {
    this.shortInformation = !this.shortInformation;
  }

  phoneFormat(input:any) {
    if(input.startsWith(9)) {
     input = "+7" + input;
    }
    if(input.startsWith(8)) {
     input = input.substring(1);
     input = "+7" + input;
    }
    if(input!=null)return input.replace(/^\+?(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/, '+$1 ($2) $3-$4-$5');
  }

  ngOnInit(): void {
    this.getCurrentStudent();
  }
}

function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}
