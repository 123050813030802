<kendo-appbar position="top" *ngIf="authService.isUserAuthenticated()">
  <kendo-appbar-section style="position: absolute">
    <a href="/" class="logo"></a>
    <h1 class="title">ЮНИВУЗ 3.0</h1>
  </kendo-appbar-section>
  <kendo-appbar-spacer></kendo-appbar-spacer>
  <kendo-appbar-section>
    <div>
      <h1 class="title full">{{fullTitle}}</h1>
      <h1 class="title small">{{shortTitle}}</h1>
    </div>

  </kendo-appbar-section>
  <kendo-appbar-spacer></kendo-appbar-spacer>
  <kendo-menu class="personMenu">
    <kendo-menu-item text="Name">
      <ng-template kendoMenuItemTemplate let-item="item">
        <div class="menuPicture" [style.background-image]="photoUrl"></div>
        <h2 class="personFullName">{{fullName}}</h2>
        <div class="burgerMenu k-icon k-i-menu k-icon-md"></div>
      </ng-template>
      <kendo-menu-item *ngIf="isAdmin" icon="user">
        <ng-template kendoMenuItemTemplate let-item="item">
          <div (click)="switchUser()">
            <h2 class="menuItemTitle">Просмотр от другого лица</h2>
          </div>
        </ng-template>
      </kendo-menu-item>
      <kendo-menu-item *ngIf="isSwitchActive" icon="circle">
        <ng-template kendoMenuItemTemplate let-item="item">
          <div (click)="stopUserPreview()">
            <h2 class="menuItemTitle">Остановить просмотр</h2>
          </div>
        </ng-template>
      </kendo-menu-item>
      <ng-container *ngIf="currentStudents.length > 1">
        <kendo-menu-item [cssClass]="{activeItem: activeItem(item.id)}" *ngFor="let item of currentStudents" icon="circle">
          <ng-template kendoMenuItemTemplate>
            <div (click)="changeStudent(item.id)">
              <h2 class="menuItemTitle">{{item.text}}</h2>
            </div>
          </ng-template>
        </kendo-menu-item>
      </ng-container>
      <kendo-menu-item text="logout"  icon="logout">
        <ng-template kendoMenuItemTemplate let-item="item">
          <div (click)="logOut()">
            <h2 class="menuItemTitle">Выход</h2>
          </div>
        </ng-template>
      </kendo-menu-item>
    </kendo-menu-item>
  </kendo-menu>
</kendo-appbar>
<kendo-drawer-container *ngIf="authService.isUserAuthenticated() && userHasSimpleSignature">
  <kendo-drawer
    #drawer
    [items]="items"
    [mini]="true"
    mode="push"
    [(expanded)]="expanded"
    (select)="onSelect($event)"
  >
  <ng-template kendoDrawerItemTemplate let-item>
    <a [ngClass]="{urlClass: item.return}" href="{{item.url}}" target="_blank" class="drawer" (click)="menuClick(item)" [class.disabled]="item.disabled == true">
      <div class="drawerItem">
        <kendo-badge-container>
          <span class="k-icon item-icon {{ item.icon }}"></span>
          <kendo-badge *ngIf="item.text == 'Новости и объявления' && calculatedParametr !== 0" style="background: #2192CE">{{ calculatedParametr}}</kendo-badge>
        </kendo-badge-container>
        <span class="text" *ngIf="expanded">{{ item.text }}</span>
      </div>
    </a>
  </ng-template>
  </kendo-drawer>

  <kendo-drawer-content>
    <kendo-breadcrumb
      (itemClick)="onItemClick($event)"
      [items]="breadcrumbitems"
    ></kendo-breadcrumb>
    <router-outlet></router-outlet>
  </kendo-drawer-content>
</kendo-drawer-container>
<login-page *ngIf="showLoginPage()"></login-page>
<callback *ngIf="showCallbackPage()"></callback>
<joining-stud-person-to-regulation *ngIf="!userHasSimpleSignature"></joining-stud-person-to-regulation>
